export default {
  name: 'el',
  el: {
    colorpicker: {
      confirm: 'Εντάξει',
      clear: 'Καθαρισμός',
    },
    datepicker: {
      now: 'Τώρα',
      today: 'Σήμερα',
      cancel: 'Ακύρωση',
      clear: 'Καθαρισμός',
      confirm: 'Εντάξει',
      selectDate: 'Επιλέξτε ημέρα',
      selectTime: 'Επιλέξτε ώρα',
      startDate: 'Ημερομηνία Έναρξης',
      startTime: 'Ωρα Έναρξης',
      endDate: 'Ημερομηνία Λήξης',
      endTime: 'Ωρα Λήξης',
      prevYear: 'Προηγούμενο Έτος',
      nextYear: 'Επόμενο Έτος',
      prevMonth: 'Προηγούμενος Μήνας',
      nextMonth: 'Επόμενος Μήνας',
      year: 'Έτος',
      month1: 'Ιανουάριος',
      month2: 'Φεβρουάριος',
      month3: 'Μάρτιος',
      month4: 'Απρίλιος',
      month5: 'Μάιος',
      month6: 'Ιούνιος',
      month7: 'Ιούλιος',
      month8: 'Αύγουστος',
      month9: 'Σεπτέμβριος',
      month10: 'Οκτώβριος',
      month11: 'Νοέμβριος',
      month12: 'Δεκέμβριος',
      // week: 'εβδομάδα',
      weeks: {
        sun: 'Κυρ',
        mon: 'Δευ',
        tue: 'Τρι',
        wed: 'Τετ',
        thu: 'Πεμ',
        fri: 'Παρ',
        sat: 'Σαβ',
      },
      months: {
        jan: 'Ιαν',
        feb: 'Φεβ',
        mar: 'Μαρ',
        apr: 'Απρ',
        may: 'Μαϊ',
        jun: 'Ιουν',
        jul: 'Ιουλ',
        aug: 'Αυγ',
        sep: 'Σεπ',
        oct: 'Οκτ',
        nov: 'Νοε',
        dec: 'Δεκ',
      },
    },
    select: {
      loading: 'Φόρτωση',
      noMatch: 'Δεν βρέθηκαν αποτελέσματα',
      noData: 'Χωρίς δεδομένα',
      placeholder: 'Επιλογή',
    },
    cascader: {
      noMatch: 'Δεν βρέθηκαν αποτελέσματα',
      loading: 'Φόρτωση',
      placeholder: 'Επιλογή',
      noData: 'Χωρίς δεδομένα',
    },
    pagination: {
      goto: 'Μετάβαση σε',
      pagesize: '/σελίδα',
      total: 'Σύνολο {total}',
      pageClassifier: '',
      page: 'Page', // to be translated
      prev: 'Go to previous page', // to be translated
      next: 'Go to next page', // to be translated
      currentPage: 'page {pager}', // to be translated
      prevPages: 'Previous {pager} pages', // to be translated
      nextPages: 'Next {pager} pages', // to be translated
    },
    messagebox: {
      title: 'Μήνυμα',
      confirm: 'Εντάξει',
      cancel: 'Ακύρωση',
      error: 'Άκυρη εισαγωγή',
    },
    upload: {
      deleteTip: 'Πάτησε Διαγραφή για αφαίρεση',
      delete: 'Διαγραφή',
      preview: 'Προεπισκόπηση',
      continue: 'Συνέχεια',
    },
    table: {
      emptyText: 'Χωρίς Δεδομένα',
      confirmFilter: 'Επιβεβαίωση',
      resetFilter: 'Επαναφορά',
      clearFilter: 'Όλα',
      sumText: 'Σύνολο',
    },
    tree: {
      emptyText: 'Χωρίς Δεδομένα',
    },
    transfer: {
      noMatch: 'Δεν βρέθηκαν αποτελέσματα',
      noData: 'Χωρίς δεδομένα',
      titles: ['Λίστα 1', 'Λίστα 2'],
      filterPlaceholder: 'Αναζήτηση',
      noCheckedFormat: '{total} Αντικείμενα',
      hasCheckedFormat: '{checked}/{total} επιλεγμένα',
    },
    image: {
      error: 'FAILED', // to be translated
    },
    pageHeader: {
      title: 'Back', // to be translated
    },
    popconfirm: {
      confirmButtonText: 'Yes', // to be translated
      cancelButtonText: 'No', // to be translated
    },
  },
}
